import { memo, useCallback } from 'react';
import { cn, Popup } from '@divlab/divanui';

import Link from '@Navigation/Link';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import useDeps from '@Contexts/DI/useDeps';
import styles from './OptionsPopup.module.css';

import type { FC, HTMLAttributes, MouseEvent as ReactMouseEvent } from 'react';
import type { FilterView } from '@Types/Filters';

export interface OptionData {
  id: string;
  name: string;
  selected?: boolean;
  link?: string;
  testId?: string;
}

export interface OptionsPopupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  label: string;
  options: OptionData[];
  visible?: boolean;
  filterView?: FilterView;
  onClose?: (e: MouseEvent | ReactMouseEvent) => void;
}

const OptionsPopup: FC<OptionsPopupProps> = (props) => {
  const { className, label, options, visible, filterView, onClose, ...restProps } = props;
  const ref = useOnClickOutside<HTMLDivElement>(onClose, !visible);
  const { analytics } = useDeps();
  const isViewSticky = filterView === 'sticky';

  const handleClick = useCallback(
    (e: ReactMouseEvent, optionName: string) => {
      analytics.dispatchEvent('catalog.sorting.accept', {
        optionName,
        status: isViewSticky ? 'закреп' : 'не закреп',
      });
      if (onClose) onClose(e);
    },
    [analytics, isViewSticky, onClose],
  );

  const handleClose = useCallback(
    (e: MouseEvent | ReactMouseEvent) => {
      if (onClose) onClose(e);
    },
    [onClose],
  );

  return (
    <Popup
      {...restProps}
      className={cn(styles.popup, { [styles.visible]: visible }, className)}
      visible={visible}
      testid='window-sorting-by'
      ref={ref}
    >
      <div className={styles.containerLabel} onClick={handleClose}>
        <span className={styles.label}>{label}</span>
        <div className={styles.arrow} />
      </div>

      <div className={styles.options}>
        {options.map((option) => (
          <Link
            to={option.link}
            className={cn(styles.option, { [styles.selected]: option.selected })}
            key={option.id}
            ssr={false}
            onClick={(e) => handleClick(e, option.name)}
            data-testid={option.testId}
          >
            {option.name}
          </Link>
        ))}
      </div>
    </Popup>
  );
};

export default memo(OptionsPopup);
