import { memo, useMemo, useCallback } from 'react';
import { cn, Gallery, RawHtml } from '@divlab/divanui';

import * as Filtrator from '@Stores/Filtrator';
import useTranslation from '@Queries/useTranslation';
import useDeps from '@Contexts/DI/useDeps';
import useNavigation from '@Navigation/useNavigation';
import Link from '@Navigation/Link';
import styles from './FilterSelectedValues.module.css';

import type { FiltersValue } from '@Types/Filters';
import type { FiltratorId } from '@Stores/Filtrator';
import type { FC, HTMLAttributes, ReactElement } from 'react';

export interface FilterSelectedValuesProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  filtratorId: FiltratorId;
  values: readonly FiltersValue[];
  onRemoveFilter?: () => void;
  scrollable?: boolean;
}

export interface ContainerProps {
  scrollable?: boolean;
  children: ReactElement[];
}

const Container: FC<ContainerProps> = (props) => {
  const { scrollable, children } = props;

  return scrollable ? (
    <Gallery className={styles.gallery}>{children}</Gallery>
  ) : (
    <div className={styles.wrapper}>{children}</div>
  );
};

const FilterSelectedValues: FC<FilterSelectedValuesProps> = (props) => {
  const { className, values, filtratorId, onRemoveFilter, scrollable = true, ...restProps } = props;
  const { pathname } = useNavigation();
  const filtrator = Filtrator.useFiltrator(filtratorId);
  const { t } = useTranslation();
  const { analytics } = useDeps();

  const items = useMemo(() => {
    return values.map((value) => {
      let valueName = value.name;
      let parameterName = '';
      const parameter = filtrator.parameters[value.parameterId];

      if (value.type === 'range') {
        parameterName = parameter.name;
        valueName = `${t('ui.range.from')} ${value.value[0]} ${t('ui.range.to')} ${value.value[1]}`;
      }

      return {
        ...value,
        id: value.parameterId,
        name: [parameterName, valueName, parameter.unit].filter(Boolean).join(' '),
      };
    });
  }, [filtrator.parameters, values, t]);

  const handleRemoveFilter = useCallback(
    (e, item) => {
      if (filtrator.withoutUrl) {
        Filtrator.resetOne(filtratorId, item);

        if (onRemoveFilter) onRemoveFilter();
      }

      analytics.dispatchEvent('filters.clear', {
        allAppliedFilters: [item.name],
        label: 'Все фильтры',
        status: 'не закреп',
      });
    },
    [filtratorId, filtrator.withoutUrl, onRemoveFilter, analytics],
  );

  return (
    <div {...restProps} className={cn(styles.values, className)}>
      <Container scrollable={scrollable}>
        {items.map((item) => (
          <div key={`${item.name}_${item.parameterId}`} className={styles.linkWrapper}>
            <Link
              to={filtrator.withoutUrl ? undefined : `${pathname}${item.resetLink}`}
              preventDefault={filtrator.withoutUrl}
              className={styles.selectedValue}
              ssr={false}
              onClick={(e) => handleRemoveFilter(e, item)}
            >
              <RawHtml className={styles.content} html={item.name} />
            </Link>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default memo(FilterSelectedValues);
